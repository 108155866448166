import React, { Component } from 'react';
import { Feed, Seo } from 'components';
import { Hello } from 'components/HelloMain';
import { About } from 'components/AboutMain';
import { Showreel } from 'components/Showreel';
import { Partners } from 'components/Partners';
import { graphql } from 'gatsby';

class Main extends Component {
  render() {
    let list = this.props.data.site.siteMetadata.main;

    return (
      <>
        <Seo description="Nimax Digital-agency. We help companies benefit from digital technologies and to introduce new work approaches and digital technologies." />
        <Hello video={['/hello/header-big.h264.mp4']} />
        <Feed list={list} />
        <About />
        <Showreel />
        <Partners />
      </>
    );
  }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        main
      }
    }
  }
`;

export default Main;
