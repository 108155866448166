import React, { Component } from 'react';

import { classnames, classname } from 'utils';

import './Partners.css';

export class Partners extends Component {
  cl = classname('partners');

  render() {
    const { className } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
				<div class="title heading heading_level_1">Find us here</div>
				<div className={this.cl('items')}>
					<a href='https://clutch.co/profile/nimax' target='_blank' className={this.cl('item')}>
						<div class="img"><img src="https://static.nimax.ru/partners/clutch.png" /></div>
						Visit the page
					</a>
					<a href='https://www.sortlist.com/agency/nimax' target='_blank' className={this.cl('item')}>
						<div class="img"><img src="https://static.nimax.ru/partners/sl.svg" /></div>
						Visit the page
					</a>
					<a href='https://www.designrush.com/agency/profile/nimax-digital-agency' target='_blank' className={this.cl('item')}>
						<div class="img"><img src="https://static.nimax.ru/partners/designrush.png" /></div>
						Visit the page
					</a>
				</div>
      </div>
    );
  }
}
