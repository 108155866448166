import React, { Component } from 'react';

import Video from 'elements/Video/Video';
import { classnames, classname } from 'utils';

import './Hello.css';

export class Hello extends Component {
  cl = classname('hello');

  render() {
    const { className, video } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        <div className={this.cl('wrapper')}>
          <div className={this.cl('about')}>
            <h1 className={this.cl('title')}>
              Digital agency <br />
              for Tech projects
            </h1>
            <div className={this.cl('descr', { desktop: true })}>
              We&nbsp;help tech companies grow by&nbsp;incorporating strategic thinking, design and marketing technologies. We&nbsp;know how to&nbsp;showcase complex technologies in&nbsp;a&nbsp;way to&nbsp;make them understandable and desirable.
            </div>
            <div className={this.cl('descr', { phone: true })}>
              We&nbsp;help tech companies grow by&nbsp;incorporating strategic thinking, design and marketing technologies. We&nbsp;know how to&nbsp;showcase complex technologies in&nbsp;a&nbsp;way to&nbsp;make them understandable and desirable.
            </div>
          </div>
          <div className={this.cl('services')}>
            <a href="/brands/" className={this.cl('link')}>
              Branding
            </a>
            <a href="/ux-ui/" className={this.cl('link')}>
              UX/UI Design
            </a>
            <a href="/soft/" className={this.cl('link')}>
              Web Development
            </a>
            <a href="/performance/" className={this.cl('link')}>
              Performance
            </a>
            <a href="/communications/" className={this.cl('link')}>
              Communications
            </a>
          </div>
        </div>
        <div className={this.cl('bg')}>
          <Video autoplay={true} className={this.cl('video')} src={video} loop={true} />
        </div>
      </div>
    );
  }
}
