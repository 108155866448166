import React, { Component } from 'react';

import Icon  from 'elements/Icon/Icon';
import { classnames, classname, createVideo, createPreview, createImage } from 'utils';

import './Video.css';

export class Video extends Component {
  cl = classname('video-new');

  static defaultProps = {
    autoPlay: false
  };

  constructor(props) {
    super(props);

    this.state = {
      play: false,
      initial: props.autoPlay
    };
  }

  componentDidMount() {
    if (!process.browser) return;

    if (this.state.initial) {
      this.observe();
    }
  }

  componentDidUpdate() {
    if (this.state.initial) {
      if (this.state.play) {
        this.play();
      } else {
        this.pause();
      }

      if (!this.observer) {
        this.observe();
      }
    }
  }

  observe = () => {
    this.observer = new IntersectionObserver((entries) => {
      if (this.props.autoPlay) {
        this.setState({
          play: entries[0].isIntersecting
        });
      } else {
        if (!entries[0].isIntersecting) {
          this.setState({
            play: false
          });
        }
      }
    });

    this.observer.observe(this.block);
  };

  play = () => {
    const play = this.block.play();

    if (play !== undefined) {
      play.catch((err) => {
        //todo::написать ошибку в sentry
        console.error(err);
      });
    }
  };

  pause = () => {
    const pause = this.block.pause();

    if (pause !== undefined) {
      pause.catch(() => {
        //todo::написать ошибку в sentry
      });
    }
  };

  onToggle = () => {
    this.setState((state) => ({
      play: !state.play,
      initial: true
    }));
  };

  onMove = () => {
    this.setState({
      move: true
    });
    clearTimeout(this.moveTimer);
    this.moveTimer = setTimeout(() => {
      this.setState({
        move: false
      });
    }, 1000);
  };

  render() {
    const { className, src, width, height, loop, muted, autoPlay, poster } = this.props;

    const classes = classnames(className, this.cl({ ...this.state, single: autoPlay }));

    return autoPlay ? (
      <video
        className={classes}
        src={createVideo(src, width, height)}
        loop={true}
        ref={(block) => (this.block = block)}
        loading={'lazy'}
        muted={muted || autoPlay}
      />
    ) : (
      <button type={'button'} className={classes} onMouseMove={() => this.onMove()} onClick={() => this.onToggle()}>
        <img
          className={this.cl('poster')}
          src={poster ? createImage(poster, width, height) : createImage(src, width, height)}
          alt=""
          loading={'lazy'}
        />
        <video
          className={this.cl('video')}
          src={createVideo(src, width, height)}
          loop={loop}
          ref={(block) => (this.block = block)}
        />

        <div className={this.cl('control')}>
          <Icon name={!this.state.play ? 'play' : 'pause'} />
        </div>
      </button>
    );
  }
}
