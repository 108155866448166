import React, { Component } from 'react';
import { classnames, classname } from 'utils';
import './About.css';

export class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOnDetails: ''
    };
    this.handleClick = this.handleClick.bind(this);
  }

  cl = classname('about-main');

  handleClick(selector) {
    if ((window.innerWidth || document.documentElement.clientWidth) < 1200) {
      if (this.state.isToggleOnDetails === selector) {
        this.setState(() => ({
          isToggleOnDetails: ''
        }));
      } else {
        this.setState(() => ({
          isToggleOnDetails: selector
        }));
      }
    }
  }

  render() {
    const { className } = this.props;
    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        <div className={this.cl('wrapper')}>
          <div className={this.cl('title')}>A unique combination of strategic approach and strong production</div>
          <div className={this.cl('list')}>
            <div className={this.cl('item')}>
              With the help of strategy, we combine separate projects and methods into complex solutions that benefit
              companies and help them grow.
            </div>
          </div>
        </div>
        <div className={this.cl('links-overlay')}>
          <div
            className={`${this.cl('link-item')} ${
              this.state.isToggleOnDetails === 'branding' && this.cl('link-item-active')
            }`}
            onClick={() => this.handleClick('branding')}
          >
            <h2
              className={`${this.cl('link-title')} ${
                this.state.isToggleOnDetails === 'branding' && this.cl('link-title-active')
              }`}
            >
              Branding
            </h2>
            <ol
              className={`${this.cl('details')} ${
                this.state.isToggleOnDetails === 'branding' && this.cl('details-active')
              }`}
            >
              <li>Strategy and positioning</li>
              <li>Naming</li>
              <li>Visual identity</li>
              <li>Design support</li>
              <li>Employer brand</li>
            </ol>
          </div>
          <div
            className={`${this.cl('link-item')} ${
              this.state.isToggleOnDetails === 'ux-ui' && this.cl('link-item-active')
            }`}
            onClick={() => this.handleClick('ux-ui')}
          >
            <h2
              className={`${this.cl('link-title')} ${
                this.state.isToggleOnDetails === 'ux-ui' && this.cl('link-title-active')
              }`}
            >
              UX/UI
            </h2>
            <ol
              className={`${this.cl('details')} ${
                this.state.isToggleOnDetails === 'ux-ui' && this.cl('details-active')
              }`}
            >
              <li>Research and design</li>
              <li>UX audit</li>
              <li>Interface design</li>
              <li>No-code websites</li>
            </ol>
          </div>
          <div
            className={`${this.cl('link-item')} ${
              this.state.isToggleOnDetails === 'digital' && this.cl('link-item-active')
            }`}
            onClick={() => this.handleClick('digital')}
          >
            <h2
              className={`${this.cl('link-title')} ${
                this.state.isToggleOnDetails === 'digital' && this.cl('link-title-active')
              }`}
            >
              Web development
            </h2>
            <ol
              className={`${this.cl('details')} ${
                this.state.isToggleOnDetails === 'digital' && this.cl('details-active')
              }`}
            >
              <li>Website</li>
              <li>Portal websites, services and in-house systems</li>
              <li>Support</li>
              <li>Website audit</li>
            </ol>
          </div>
          <div
            className={`${this.cl('link-item')} ${
              this.state.isToggleOnDetails === 'performance' && this.cl('link-item-active')
            }`}
            onClick={() => this.handleClick('performance')}
          >
            <h2
              className={`${this.cl('link-title')} ${
                this.state.isToggleOnDetails === 'performance' && this.cl('link-title-active')
              }`}
            >
              Performance-based marketing
            </h2>
            <ol
              className={`${this.cl('details')} ${
                this.state.isToggleOnDetails === 'performance' && this.cl('details-active')
              }`}
            >
              <li>Contextual advertising</li>
              <li>Targeted advertising</li>
              <li>SEO</li>
              <li>ASO</li>
              <li>End-to-End Analytics</li>
            </ol>
          </div>
          <div
            className={`${this.cl('link-item')} ${
              this.state.isToggleOnDetails === 'communication' && this.cl('link-item-active')
            }`}
            onClick={() => this.handleClick('communication')}
          >
            <h2
              className={`${this.cl('link-title')} ${
                this.state.isToggleOnDetails === 'communication' && this.cl('link-title-active')
              }`}
            >
              Communication
            </h2>
            <ol
              className={`${this.cl('details')} ${
                this.state.isToggleOnDetails === 'communication' && this.cl('details-active')
              }`}
            >
              <li>Communication strategy</li>
              <li>SMM</li>
              <li>Content marketing</li>
              <li>Special Projects</li>
              <li>Influencer marketing</li>
              <li>Video and photo production</li>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}
